<script>
  import { onMount, getContext, createEventDispatcher } from 'svelte';
  import I18n from '../../utilities/I18n.js';
  import { t } from '../../utilities/general.js';
  import LuminaireTypeModal from './LuminaireTypeModal.svelte';
  import Button from 'sveltestrap/src/Button.svelte';
  import FormInput from '../generic/form/FormInput.svelte';

	const dispatch = createEventDispatcher();
  const context = getContext('context');
  const { code, mode, editable, building, roomGroup } = context;

  const newLuminaire = async () => {
    const response = await fetch(roomGroup.new_luminaire_path);
    const data = await response.json();
    luminaires = [...luminaires, data];
  }

  const newLuminaireType = async () => {
    const response = await fetch(roomGroup.new_luminaire_type_path);
    const data = await response.json();
    return data;
  }

  export let luminaire;
  export let luminaires;
  export let luminaireTypes;
  export let name = '';
  
  let luminaireTypeModalIsOpen = false;
  let luminaireType;
  let hasError = false;
  $: deleted = (luminaire ? luminaire._destroy || false : false);

  const toggleLuminaireTypeModal = () => { 
    if (luminaire.lighttool_luminaire_type_id) luminaireType = {...luminaireTypes.filter(t => t.id === luminaire.lighttool_luminaire_type_id)[0]}
    else luminaireType = null
    luminaireTypeModalIsOpen = !luminaireTypeModalIsOpen;
  }
  
</script>

<style>
  .deleted {
    display: none
  }
  td:first-of-type {
    padding-left: 0;
  }
  td:last-of-type {
    padding-right: 0;
  }
</style>

<tr class:deleted>
  {#if luminaire}
    <input type="hidden" name="{name}[id]" value="{luminaire.id || null}">
    <input type="hidden" name="{name}[_destroy]" value={deleted}>
    <td><FormInput type="number" name="{name}[count]" nolabel fullwidth bind:value={luminaire.count} on:change readonly={!editable} /></td>
    <td>
      <FormInput type="select" name="{name}[lighttool_luminaire_type_id]" bind:value={luminaire.lighttool_luminaire_type_id} nolabel fullwidth on:change readonly={!editable}>
        {#each luminaireTypes as type, index (`luminaire_type_${type.id}`)}
          <option value={type.id}>{t(type.full_name)}</option>
        {/each}
      </FormInput>
    </td>
    {#if editable}
    <td>
      {#if luminaire.lighttool_luminaire_type_id}
        <Button type="button" on:click={toggleLuminaireTypeModal} size="sm"><span class="glyphicon glyphicon-pencil"></span></Button>
      {:else}
        <Button type="button" on:click={toggleLuminaireTypeModal} size="sm"><span class="glyphicon glyphicon-plus"></span></Button>
      {/if}
    </td>
    <td><Button type="button" on:click={() => { luminaire._destroy = true; dispatch('change')}} size="sm"><span class="glyphicon glyphicon-remove"></span></Button></td>
    {/if}
  {:else if editable}
    <td colspan="5">
      <Button type="button" size="sm" on:click={newLuminaire}>
        <span class="glyphicon glyphicon-plus"></span>
        <span>{I18n.t('lighttool.luminaire.add_new')}</span>
      </Button>
    </td>
  {/if}
</tr>



{#if luminaire && editable}
  <LuminaireTypeModal bind:isOpen={luminaireTypeModalIsOpen} bind:luminaireTypes bind:luminaireTypeId={luminaire.lighttool_luminaire_type_id} bind:luminaireType on:change />
{/if}
