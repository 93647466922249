import { writable } from 'svelte/store';

/**
 * generic svelte store that uses fetch to retrieve and update its content
 */
export const store = function() {
  const store = writable([]);
  store.paths = {};

  // add function to handle API requests
  store.request = async (path, options = {}, data = {}) => {
    if (!path) {
      return { error: 'No path provided' }
    }

    const requestOptions = {
      headers: {},
      redirect: 'manual',
      ...options,
    }
  
    if ([method.POST, method.PUT, method.PATCH].includes(options.method)) {
      if (data instanceof FormData) {
        requestOptions.body = data;
      } else {
        requestOptions.body = JSON.stringify(data);
        requestOptions.headers['Content-Type'] = 'application/json'; 
      }
    }

    // execute request
    let response, responseData;
    try {
      response = await fetch(path, requestOptions);

      if (response.ok) {  
        responseData = await response.json();

        if (responseData.error) {
          throw responseData.error;
        } else {
          return { data: responseData };
        }
      } else {
        responseData = await response.json();
        throw responseData.error;
      }
    } catch (e) {
      console.warn('fetchStore encountered an error:', response.statusText, e);
      let error = e instanceof Array ? e: [e];

      return { error }
    }
  }

  // helper functions
  store.fetch = async () => {
    const result = await store.request(store.paths.index);
    store.set(result.data || []);
    return result;
  }
  store.post = async (data) => {
    const result = await store.request(store.paths.create, { method: method.POST }, data);
    store.fetch();
    return result;
  }
  store.put = async (url, data) => {
    const result = await store.request(url, { method: method.PUT }, data);
    store.fetch();
    return result;
  }
  store.delete = async (url) => {
    const result = await store.request(url, { method: method.DELETE });
    store.fetch();
    return result;
  }

  // initialization function
  // sets appropriate API paths and fetches initial data
  store.init = (paths) => {
    store.paths = paths;
    store.fetch();
  }

  return store;
}

const method = Object.freeze({
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete'
})

export default store;
