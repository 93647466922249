import I18n from "i18n-js";
import { getLocale } from "./html.js";

I18n.defaultLocale = 'de';
I18n.locale = getLocale();

try {
    require("./translations.js");
} catch (ex) {
    // noop
}

export default I18n;
