<script>
  import I18n from '../../utilities/I18n.js';
  import { t } from '../../utilities/general.js';

  import { Table, Button } from 'sveltestrap';
  import { luminaireTypes } from '../../stores/lighttool_exterior/luminaireTypesStore';
  import LuminaireTypeModal from "./LuminaireTypeModal.svelte";

  export let site = {},
             debug;
  
  let activeLuminaireType = {},
      luminaireTypeModalIsOpen = false;

  let errors = [];

  const openLuminaireTypeModal = (luminaireType) => {
    activeLuminaireType = luminaireType || {}
    luminaireTypeModalIsOpen = true;
  }

  // initialize stores
  luminaireTypes.init(site.paths.luminaire_types);
</script>

<style>
  .luminaire-type-actions {
    white-space: nowrap;
    text-align: right;
  }
  .luminaire-types td {
    vertical-align: middle;
  }
</style>

<Table class="table-condensed">
  <thead>
    <tr>
      <th scope="col" class="col-xs-12">{I18n.t('activerecord.attributes.lighttool_exterior/luminaire_type.full_name')}</th>
      <th />
    </tr>
  </thead>
  <tbody class="luminaire-types">
    {#each $luminaireTypes as type}
      <tr>
        <td>{t(type.full_name)}</td>
        <td class="luminaire-type-actions">
          {#if type.datasheet_url}
            <a href="{type.datasheet_url}" target="_blank" class="btn btn-sm btn-default" title={I18n.t('documents.buttons.download', { property_name: I18n.t('activerecord.attributes.lighttool_exterior/luminaire_type.datasheet') })}>
              <span class="glyphicon glyphicon-file"/>
              <span class="hidden-xs">{I18n.t('activerecord.attributes.lighttool_exterior/luminaire_type.datasheet')}</span>
            </a>
          {/if}
          <Button type="button" size="sm" on:click={() => openLuminaireTypeModal(type)} title="{I18n.t('helpers.titles.edit', { model: I18n.t('activerecord.models.lighttool_exterior/luminaire_type', { count: 1 }) })}">
            <span class="glyphicon glyphicon-pencil"/>
            <span class="hidden-xs">{I18n.t('helpers.titles.edit', { model: I18n.t('activerecord.models.lighttool_exterior/luminaire_type', { count: 1 }) })}</span>
          </Button>
        </td>
      </tr>
    {/each}
    <tr>
      <td colspan="5">
        <Button type="button" size="sm" on:click={openLuminaireTypeModal}>
          <span class="glyphicon glyphicon-plus"></span><span>{I18n.t('helpers.titles.new_luminaire_type')}</span>
        </Button>
      </td>
    </tr>
  </tbody>
</Table>

<LuminaireTypeModal {site} bind:isOpen={luminaireTypeModalIsOpen} bind:luminaireType={activeLuminaireType} {debug} />
