<script>
  import I18n from "../../utilities/I18n.js";

  import Table from "sveltestrap/src/Table.svelte";
  import InstalledLuminairesEntry from "./InstalledLuminairesEntry.svelte";
  import Alert from "sveltestrap/src/Alert.svelte";

  export let configId;
  export let luminaires = [];
  export let readonly;
  export let name = "";

  export let luminaireTypes;
  let hasError = false;
</script>

<fieldset class="group col-xs-12">
  <legend><h4>{I18n.t("lighttool.room_group.installed_luminaires")}</h4></legend>
  {#if hasError}
    <Alert color="danger">{I18n.t("lighttool.application.alert.saving_failed")}</Alert>
  {/if}
  <Table>
    <thead>
      <tr>
        <th scope="col" class="col-xs-2 count">{I18n.t("lighttool.luminaire.count")}</th>
        <th scope="col" class="col-xs-10">{I18n.t("lighttool.luminaire.other")}</th>
        <th colspan="2" scope="col" class="col-xs-2" />
      </tr>
    </thead>
    <tbody>
      {#if luminaires.length > 0}
        {#each luminaires as luminaire}
          <InstalledLuminairesEntry {name} {luminaire} {configId} bind:luminaires bind:luminaireTypes {readonly} on:change />
        {/each}
      {:else}
        <tr><td colspan="3"><i>{I18n.t("lighttool.luminaire.empty")}</i></td></tr>
      {/if}
      {#if !readonly}<InstalledLuminairesEntry {name} {configId} bind:luminaires bind:luminaireTypes />{/if}
    </tbody>
  </Table>
</fieldset>

<style>
  legend {
    border-bottom: none;
    margin: 12px 0;
  }
  th:first-child,
  td:first-child {
    padding-left: 0;
  }
  th:last-child,
  td:last-child {
    padding-right: 0;
  }
  .count {
    min-width: 10rem;
  }
</style>
