<script>
  import { createEventDispatcher } from 'svelte';
  import I18n from '../../utilities/I18n.js';
  import { t } from '../../utilities/general.js';
  import Button from 'sveltestrap/src/Button.svelte';
  import FormInput from '../generic/form/FormInput.svelte';

  import { luminaireTypes } from '../../stores/lighttool_exterior/luminaireTypesStore';

	const dispatch = createEventDispatcher();

  export let luminaire,
             name = '',
             disabled;
  
  $: deleted = (luminaire ? luminaire._destroy || false : false);
</script>

<style>
  .deleted {
    display: none
  }
  td:first-of-type {
    padding-left: 0;
  }
  td:last-of-type {
    padding-right: 0;
  }
</style>

<tr class:deleted>
  <input type="hidden" name="{name}[id]" value="{luminaire.id || null}">
  <input type="hidden" name="{name}[_destroy]" value={deleted}>
  <td>
    <FormInput type="number"
               name="{name}[count]"
               nolabel
               fullwidth
               bind:value={luminaire.count}
               on:change
               {disabled} />
  </td>
  <td>
    <FormInput type="select"
               name="{name}[lighttool_exterior_luminaire_type_id]"
               options={$luminaireTypes}
               bind:value={luminaire.lighttool_exterior_luminaire_type_id}
               nolabel
               fullwidth
               on:change
               {disabled}>
    </FormInput>
  </td>
  <td>
    {#if !disabled}
      <Button type="button" 
              aria-label="{I18n.t('helpers.titles.delete', { model: I18n.t('activerecord.models.lighttool_exterior/luminaire', { count: 1 }) })}"
              on:click={() => { luminaire._destroy = true; dispatch('change')}}
              size="sm">
        <span class="glyphicon glyphicon-remove"/>
      </Button>
    {/if}
  </td>
</tr>