<script>
  import { onMount, setContext, getContext } from "svelte";

  import I18n from "../../utilities/I18n.js";

  import Button from "sveltestrap/src/Button.svelte";
  import Alert from "sveltestrap/src/Alert.svelte";
  import RoomGroupTable from "./RoomGroupTable.svelte";
  import FormInput from "../generic/form/FormInput.svelte";
  import FileUpload from "../generic/form/FileUpload.svelte";
  import AddressTable from "./AddressTable.svelte";
  import EnergyBalanceTable from "./EnergyBalanceTable.svelte";

  export let code,
    mode,
    version,
    editable,
    building,
    standards = [],
    debug;

  const lighttool = mode === "lighttool",
    v1 = version == "v1",
    lightcheck = mode === "lightcheck";

  setContext("context", {
    code,
    lighttool,
    lightcheck,
    v1,
    editable,
    building,
  });

  let room_groups = [];
  let errors = [];
  let submitting = false;
  let energyBalance = {};

  let csrfparam = "authenticity-token";
  let csrftoken = "";

  const submitBuildingForm = () => {
    submitting = true;
    errors = [];
    const form = document.querySelector("form#buildingform");
    const request = new XMLHttpRequest();

    request.open("PUT", building.update_path);
    request.onload = (e) => {
      let res;
      submitting = false;
      if (request.status === 200) back();
      else
        try {
          res = JSON.parse(request.response);
          errors = res.error;
        } catch (e) {
          errors = [I18n.t("lighttool.application.alert.saving_failed")];
        }
    };
    request.send(new FormData(form));
  };

  let standardMet;
  let fileDragging = false;
  let fileError = false;

  const back = () => {
    window.location.href = building.return_path;
  };

  onMount(() => {
    try {
      csrftoken = Array.from(document.getElementsByTagName("meta"))
        .filter((m) => m.getAttribute("name") === "csrf-token")[0]
        .getAttribute("content");
      csrfparam = Array.from(document.getElementsByTagName("meta"))
        .filter((m) => m.getAttribute("name") === "csrf-param")[0]
        .getAttribute("content");
    } catch (e) {
      console.error(e);
    }

    if (lightcheck && !building.assessment) building.assessment = I18n.t("lighttool.building.assessment.default");
  });
</script>

{#each errors as e}
  <Alert color="warning">{e}</Alert>
{/each}

<h2><strong>{building.properties.name}</strong></h2>

<form id="buildingform" on:submit|preventDefault={submitBuildingForm} class="row">
  <input type="hidden" id="building_id" name="building[id]" value={building.id} />

  <fieldset class="group col-xs-12">
    <legend><h3>{I18n.t("lighttool.section.addresses")}</h3></legend>
    <AddressTable />
  </fieldset>

  <fieldset class="group col-xs-12">
    <legend>
      <h3>{I18n.t("lighttool.section.room_groups")}</h3>
      <p>{I18n.t("lighttool.application.alert.room_group_limit")}</p>
    </legend>
    <RoomGroupTable {building} {mode} {code} {editable} bind:room_groups bind:standardId={building.lighttool_standard_id} bind:standardMet bind:energyBalance />
  </fieldset>

  <fieldset class="group col-xs-12 row">
    {#if editable && v1}
      <div class="col-xs-12 col-md-6">
        <FormInput
          fullwidth
          type="select"
          options={standards}
          name="building[lighttool_standard_id]"
          label={I18n.t("lighttool.building.requirements.selected_standard")}
          bind:value={building.lighttool_standard_id}
          readonly={!editable} />
      </div>

      <div class="col-xs-12 col-md-6">
        {#if building.lighttool_standard_id && room_groups.length > 0}
          {#if standardMet}
            <p class="standard-result text-success">{@html I18n.t("lighttool.building.requirements.met")}</p>
          {:else}
            <p class="standard-result text-danger">{@html I18n.t("lighttool.building.requirements.not_met")}</p>
          {/if}
        {/if}
      </div>
    {:else if room_groups.length > 0}
      <div class="col-xs-12">
        <input type="hidden" name="building[lighttool_standard_id]" value={building.lighttool_standard_id} />
        {#if standardMet}
          <p class="text-success">{@html I18n.t("lighttool.building.requirements.prokilowatt.met")}</p>
        {:else}
          <p class="text-danger">{@html I18n.t("lighttool.building.requirements.prokilowatt.not_met")}</p>
        {/if}
      </div>
    {/if}
  </fieldset>

  <fieldset class="group col-xs-12">
    <legend><h3>{I18n.t("lighttool.section.energy_balance")}</h3></legend>
    <EnergyBalanceTable bind:energyBalance />
  </fieldset>

  {#if lightcheck}
    <fieldset class="group col-xs-12 col-md-6">
      <legend><h3>{I18n.t("lighttool.section.assessment")}</h3></legend>
      {#if editable}
        <FormInput fullwidth type="textarea" name="building[assessment]" bind:value={building.assessment} style="height: 26rem;" />
      {:else}
        <span>{building.assessment || "-"}</span>
      {/if}
    </fieldset>
  {/if}

  <fieldset class="group col-xs-12 col-md-6">
    {#if editable}
      <FileUpload
        name="building[picture_data]"
        bind:data={building.picture_data}
        bind:filename={building.picture_filename}
        bind:mime={building.picture_mime}
        style="margin-top: 10px"
        on:dragover={() => {
          fileDragging = true;
          fileError = false;
        }}
        on:dragleave={() => (fileDragging = false)}>
        {#if building.picture_data}
          <div><span class="glyphicon glyphicon-picture" />&nbsp;{building.picture_filename}</div>
          <div class="picture-wrapper">
            <img src={`data:${building.picture_mime};base64, ${building.picture_data}`} alt="({I18n.t('lighttool.asset.preview')} {I18n.t('lighttool.asset.failed_to_load')})" />
          </div>
        {:else}
          <div class="fileupload-placeholder" class:fileError class:fileDragging>
            <span class="glyphicon glyphicon-open" /><br />{I18n.t("lighttool.asset.name.picture")}&nbsp;{I18n.t("lighttool.asset.upload")}
          </div>
        {/if}
      </FileUpload>
    {:else if building.picture_data}
      <div><span class="glyphicon glyphicon-picture" />&nbsp;{building.picture_filename}</div>
      <div class="picture-wrapper">
        <img src={`data:${building.picture_mime};base64, ${building.picture_data}`} alt="({I18n.t('lighttool.asset.preview')} {I18n.t('lighttool.asset.failed_to_load')})" />
      </div>
    {/if}
  </fieldset>

  <fieldset class="group col-xs-12">
    <legend>&nbsp;</legend>
    <Button type="submit" color="primary" disabled={submitting}>{editable ? I18n.t("lighttool.application.save_and_close") : I18n.t("lighttool.application.close")}</Button>
  </fieldset>

  <input type="hidden" name={csrfparam} value={csrftoken} />
</form>

{#if debug}
  <pre class="debug">{JSON.stringify(getContext("context"), null, 2)}</pre>
{/if}

<style>
  .picture-wrapper {
    border: 1px solid lightgray;
    width: 100%;
    padding-top: 75%;
    position: relative;
  }
  .picture-wrapper > img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .fileupload-placeholder {
    border: 1px solid grey;
    width: 100%;
    padding-top: 30%;
    padding-bottom: 30%;
    text-align: center;
  }
  .fileupload-placeholder > .glyphicon {
    font-size: 2.5em;
    margin-bottom: 0.25em;
  }
  .fileupload-placeholder.fileError {
    box-shadow: inset 0 0 12px #dc3545;
  }
  .fileupload-placeholder.fileDragging {
    box-shadow: inset 0 0 12px #999;
  }
  legend {
    border-bottom: none;
    margin: 12px 0;
  }
  legend > p {
    font-size: 14px;
  }
  p {
    margin-top: 12px;
  }
  .standard-result {
    margin-top: 35px;
  }
</style>
