<script>
  import { onMount, getContext } from "svelte";
  import I18n from "../../utilities/I18n.js";

  import InstalledLuminaires from "./InstalledLuminaires.svelte";
  import FormInput from "../generic/form/FormInput.svelte";

  export let config;
  if (!config) config = {};
  export let readonly;
  export let luminaireTypes;
  export let name = "";
  export let windowless;
  let solarless = false;

  let attr = {};

  let { roomGroup } = getContext("context");

  const handleSolarProtectionSelect = (e) => {
    if (config.lighttool_solar_protection_type_id === 6) {
      config.lighttool_solar_protection_control_id = 5;
      solarless = true;
    } else solarless = false;
  };

  onMount(async () => {
    const response = await fetch(roomGroup.configuration_attributes_path);
    attr = await response.json();
  });

  $: solarProtectionType = config.lighttool_solar_protection_type_id;
  $: handleSolarProtectionSelect(solarProtectionType);
</script>

<fieldset class="group col-xs-12">
  <input type="hidden" name="{name}[id]" value={config.id || null} />
  <FormInput
    {readonly}
    fullwidth
    type="select"
    name="{name}[lighttool_presence_control_id]"
    label={I18n.t("lighttool.room_group.presence_control")}
    bind:value={config.lighttool_presence_control_id}
    options={attr.presence_control}
    on:change />
  <FormInput
    {readonly}
    fullwidth
    type="select"
    name="{name}[lighttool_brightness_id]"
    label={I18n.t("lighttool.room_group.brightness")}
    bind:value={config.lighttool_brightness_id}
    options={attr.brightness}
    on:change />
  <FormInput
    fullwidth
    type="select"
    name="{name}[lighttool_solar_protection_type_id]"
    label={I18n.t("lighttool.room_group.solar_protection_type")}
    bind:value={config.lighttool_solar_protection_type_id}
    options={attr.solar_protection_type}
    on:change
    on:change={handleSolarProtectionSelect}
    readonly={windowless} />
  <FormInput
    fullwidth
    type="select"
    name="{name}[lighttool_solar_protection_control_id]"
    label={I18n.t("lighttool.room_group.solar_protection_control")}
    bind:value={config.lighttool_solar_protection_control_id}
    options={attr.solar_protection_control}
    on:change
    readonly={windowless || solarless} />
  <FormInput
    fullwidth
    type="select"
    name="{name}[lighttool_daylight_control_id]"
    label={I18n.t("lighttool.room_group.daylight_control")}
    bind:value={config.lighttool_daylight_control_id}
    options={attr.daylight_control}
    on:change
    readonly={windowless} />
</fieldset>

<InstalledLuminaires name="{name}[luminaires_attributes][]" {readonly} bind:luminaires={config.luminaires_attributes} bind:luminaireTypes on:change />
