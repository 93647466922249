<script>
  import Table from 'sveltestrap/src/Table.svelte';

  export let data;

  const getEntry = (column, row) => {
    if (data.records[column] && data.records[column][row]) return data.records[column][row]
  }
</script>

<style>
  td {
    vertical-align: valign
  }
  th:first-child {
    padding-left: 0;
  }
  td:last-child {
    padding-right: 0;
  }
</style>

<Table>
  <thead>
    <tr>
      <th scope="col"></th>
      {#each data.columns as c}
      <th scope="col" class={`text-${c.align || 'left'}`}>{c.label}</th>
      {/each}
    </tr>
  </thead>
  <tbody>
    {#each data.rows as r}
      <tr>
        <th scope="row">{r.label}</th>
        {#each data.columns as c}
          <td class={`text-${c.align || 'left'}`}>{getEntry(c.key, r.key) || '-'}</td>
        {/each}
      </tr>
    {/each}
  </tbody>
</Table>