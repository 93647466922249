<script>
  import { t } from "../../../utilities/general.js";
  import FormGroup from "sveltestrap/src/FormGroup.svelte";
  import Label from "sveltestrap/src/Label.svelte";
  import I18n from "../../../utilities/I18n.js";

  export let type = "text",
    input = {},
    name = input.name,
    label = input.label,
    readonly = input.readonly,
    disabled = input.disabled,
    value,
    options = [],
    addon,
    fullwidth,
    hidden,
    step,
    style,
    rows,
    tooltip;

  const typeAction = (node) => {
    node.type = type;
  };

  $: displayValue = () => {
    if (type == "select") {
      let selected = options.find((option) => (option.id || option.value) == value);

      if (selected) return t(selected.full_name || selected.name) || selected.value;
    } else {
      return value;
    }
  };
</script>

<FormGroup class={[!fullwidth ? "col-md-6" : "", hidden ? "hidden" : ""].join(" ")}>
  {#if label}
    <Label for={name} style="text-align: left;">
      {label}

      {#if tooltip}
        <small class="input-tooltip">
          <span class="glyphicon glyphicon-info-sign" />
          {tooltip}
        </small>
      {/if}
    </Label>
  {/if}

  {#if disabled}
    <div class="form-control disabled">{displayValue() || "-"} {addon || ""}</div>
  {:else}
    <div class:input-group={addon}>
      {#if type === "select"}
        <select bind:value id={name} class="form-control" disabled={readonly || disabled} on:select on:change on:clear>
          <input type="hidden" bind:value {name} />
          <option />
          {#each options as option (option.id || option.value)}
            <option value={option.id || option.value} selected={(option.id || option.value) == value}>{t(option.name) || option.value}</option>
          {/each}
          <slot />
        </select>
        {#if addon}
          <span class="input-group-addon">{addon}</span>
        {/if}
      {:else if type === "file"}
        <span>Unsupported input type</span>
      {:else if type === "textarea"}
        <textarea bind:value id={name} class="form-control" {name} {style} {rows} {disabled} {readonly} on:blur on:focus on:keydown on:keypress on:keyup on:change on:input />
      {:else}
        <input
          use:typeAction
          id={name}
          class="form-control"
          {name}
          {step}
          {disabled}
          {readonly}
          {style}
          on:blur
          on:focus
          on:keydown
          on:keypress
          on:keyup
          on:change
          on:input
          bind:value />
        {#if addon}
          <span class="input-group-addon">{addon}</span>
        {/if}
      {/if}
    </div>
  {/if}
</FormGroup>

<style>
  .disabled {
    border: none;
    box-shadow: none;
    padding-left: 0;
  }

  .input-tooltip {
    display: block;
  }
</style>
