<script>
  import Table from '../generic/Table.svelte';
  import I18n from '../../utilities/I18n.js';

  export let energyBalance = {};

  const getColumns = () => {
    const columns = [];

    for (const key of Object.keys(energyBalance)) {
      columns.push({ key, label: I18n.t(`activerecord.attributes.lighttool_exterior/site.energy_balance.${key}`), align: 'center'},)
    }

    return columns;
  }

  $: columns = getColumns(energyBalance);
  
  $: meta = {
    columns: columns,
    rows: [
      { key: 'kW', label: I18n.t('activerecord.attributes.lighttool_exterior/site.energy_balance.power')},
      { key: 'Wm2', label: I18n.t('activerecord.attributes.lighttool_exterior/site.energy_balance.specific_power')},
      { key: 'ha', label: I18n.t('activerecord.attributes.lighttool_exterior/site.energy_balance.full_load_hours')},
      { key: 'MWha', label: I18n.t('activerecord.attributes.lighttool_exterior/site.energy_balance.energy_demand')},
      { key: 'kWhm2', label: I18n.t('activerecord.attributes.lighttool_exterior/site.energy_balance.specific_energy_demand')}
    ]
  }

  $: data = {...meta, records: energyBalance};
</script>

<Table bind:data />
