<script>
  import I18n from '../../utilities/I18n.js';

  import Table from 'sveltestrap/src/Table.svelte';
  import InstalledLuminairesEntry from './InstalledLuminairesEntry.svelte';
  import Button from 'sveltestrap/src/Button.svelte';

  import { luminaireTypes } from '../../stores/lighttool_exterior/luminaireTypesStore';

  export let config,
             name,
             disabled;

  const addLuminaire = () => {
    config.luminaires_attributes = [...config.luminaires_attributes, {}]
  }
</script>

<style>
  legend {
    border-bottom: none;
    margin: 12px 0
  }
  th:first-child, td:first-child {
    padding-left: 0;
  }
  th:last-child, td:last-child {
    padding-right: 0;
  }
  .no-luminaires {
    font-style: italic;
    padding-top: 8px;
    padding-bottom: 8px;
  }
</style>

<fieldset class="group">
  <legend><h4>{I18n.t('lighttool_exterior.section.installed_luminaires')}</h4></legend>
  <Table>
    <thead>
      <tr>
        <th scope="col" class="col-xs-3">{I18n.t('lighttool.luminaire.count')}</th>
        <th scope="col" class="col-xs-8">{I18n.t('lighttool.luminaire.other')}</th>
        <th colspan="2" scope="col" class="col-xs-2"></th>
      </tr>
    </thead>
    <tbody>
      {#each config.luminaires_attributes as luminaire}
        <InstalledLuminairesEntry {luminaire} {config} {name} {disabled} on:change />
      {/each}
      {#if $luminaireTypes.length > 0}
        {#if config.luminaires_attributes.length < 1}
          <tr><td colspan="3"><div class="no-luminaires">{I18n.t('lighttool.luminaire.empty')}</div></td></tr>
        {/if}
        {#if !disabled}
          <td colspan="5">
            <Button type="button" size="sm" on:click={addLuminaire}>
              <span class="glyphicon glyphicon-plus"></span>
              <span>{I18n.t('lighttool.luminaire.add_new')}</span>
            </Button>
          </td>
        {/if}
      {:else}
        <tr><td colspan="3"><div class="no-luminaires">{I18n.t('lighttool_exterior.notices.luminaires.no_luminaire_types')}</div></td></tr>
      {/if}
    </tbody>
  </Table>
</fieldset>
