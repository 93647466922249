<script>
  import Table from 'sveltestrap/src/Table.svelte';
  import I18n from '../../utilities/I18n.js';

  export let site = {};

  let tableRows = [
    { label: I18n.t('activerecord.attributes.lighttool_exterior/site.properties.addresses.site'), entry: site.properties.site },
    { label: I18n.t('activerecord.attributes.lighttool_exterior/site.properties.addresses.principal'), entry: site.properties.principal},
    { label: I18n.t('activerecord.attributes.lighttool_exterior/site.properties.addresses.applicant'), entry: site.properties.applicant},
    { label: `${I18n.t('activerecord.attributes.lighttool_exterior/site.properties.date')} ${I18n.t('activerecord.attributes.lighttool_exterior/site.properties.addresses.analysis_date')}`,
      entry: site.properties.analysis_date }
  ]

  if (site.properties.examiner) {
    tableRows.push({ label: I18n.t('activerecord.attributes.lighttool_exterior/site.properties.addresses.examiner'), entry: site.properties.examiner})
  }
  if (site.properties.lightcheck_date) {
    tableRows.push({ label: `${I18n.t('activerecord.attributes.lighttool_exterior/site.properties.date')} ${I18n.t('activerecord.attributes.lighttool_exterior/site.properties.addresses.check')}`,
                     entry: site.properties.lightcheck_date })
  }
</script>

<style>
  tbody th {
    padding-left: 0;
  }
  tbody td:last-child {
    padding-right: 0;
  }
</style>

<Table>
  <tbody>
    {#each tableRows as r}
    <tr>
      <th scope="row">{r.label}</th>
      <td>{@html r.entry || '-'}</td>
    </tr>
    {/each}
  </tbody>
</Table>
