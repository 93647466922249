<script>
  import { derived } from 'svelte/store';

  import I18n from '../../utilities/I18n.js';

  import FormInput from "../generic/form/FormInput.svelte";
  import InstalledLuminaires from "./InstalledLuminaires.svelte";

  import { configAttributes } from '../../stores/lighttool_exterior/configAttributesStores';
  import { luminaireTypes } from '../../stores/lighttool_exterior/luminaireTypesStore';

  export let site = {},
             key,
             config;

  let errors = [],
      editable = config.editable,
      disabled = !editable,
      scope = `site[${key}]`;

  // initialize stores
  luminaireTypes.init(site.paths.luminaire_types);
  configAttributes.init(site.paths.configuration_attributes);
  const presenceControl = derived(configAttributes, $attributes => $attributes.presence_control);
  const daylightControl = derived(configAttributes, $attributes => $attributes.daylight_control);

</script>

<style>
  legend {
    border-bottom: none;
    margin: 12px 0
  }
</style>

{#if config}
<div class="col-md-6">
    <fieldset class="group">
      <legend><h3>{I18n.t(`lighttool_exterior.section.${key}`)}</h3></legend>
      {#if config.id}
        <input type="hidden" name="{scope}[id]" value="{config.id}">
      {/if}
      <FormInput type="select"
                 name="{scope}[lighttool_exterior_presence_control_id]"
                 label="{I18n.t('activerecord.models.lighttool_exterior/presence_control')}"
                 bind:value={config.lighttool_exterior_presence_control_id}
                 options={$presenceControl}
                 on:change
                 {disabled}
                 fullwidth />
      <FormInput type="select"
                 name="{scope}[lighttool_exterior_daylight_control_id]"
                 label="{I18n.t('activerecord.models.lighttool_exterior/daylight_control')}"
                 bind:value={config.lighttool_exterior_daylight_control_id}
                 options={$daylightControl}
                 on:change
                 {disabled}
                 fullwidth />
    </fieldset>
    <InstalledLuminaires name="{scope}[luminaires_attributes][]"
                         on:change
                         bind:config
                         {disabled} />
  </div>
{/if}
