<script>
  // Code mostly taken from https://github.com/TahaSh/svelte-confirm/
  import { fly, fade } from 'svelte/transition'
  import I18n from '../../utilities/I18n.js';

  export let themeColor = 200
  
  let showDialog = false
  let functionToCall = {
    func: null,
    args: null
  }
  function callFunction () {
    showDialog = false
    functionToCall['func'](...functionToCall['args'])
  }
  function confirm (func, ...args) {
    functionToCall = {func, args}
    showDialog = true
  }
</script>

<slot confirm={confirm}></slot>

{#if showDialog}
  <div
    class="overlay"
    in:fade="{{ duration: 200 }}"
    out:fade="{{ delay: 200, duration: 200 }}"
  ></div>
  <div
    class="confirm-dialog"
    in:fly="{{
      y: -50,
      delay: 200,
      duration: 200
    }}"
    out:fly="{{
      y: -50,
      duration: 200
    }}"
  >
    <div class="message-section">
      <span class="message-title">
        <slot name="title">
          {I18n.t('shared.confirmations.index_general_delete')}
        </slot>
      </span>
    </div>
    <div
      class="actions"
      style="background: hsl({themeColor}, 30%, 97%)"
    >
      <button type='button'
        class="cancel-button btn btn-secondary"
        on:click="{() => showDialog = false }">
        <slot name="cancel">
          {I18n.t('shared.buttons.cancel')}
        </slot>
      </button>
      &nbsp;
      <button type='button'
        class="confirm-button btn btn-primary ml-3"
        on:click="{callFunction}">
        <slot name="confirm">
          {I18n.t('shared.actions.destroy')}
        </slot>
      </button>
    </div>
  </div>
{/if}

<style>
.message-title {
  font-size: 22px;
  font-weight: 500;
  display: block;
  color: hsl(0, 0%, 20%);
  line-height: 1.2;
}
.actions {
  display: flex;
  justify-content: flex-end;
  margin: 25px -40px -30px;
  padding: 15px 20px;
  border-radius: 0 0 3px 3px;
}
.confirm-dialog {
  font-family: sans-serif;
  position: absolute;
  z-index: 999;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 40px;
  border-radius: 3px;
  background: #FFF;
  max-width: 500px;
  width: calc(100% - 20px);
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
.overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  user-select: none;
  z-index: 998;
  background: hsla(0, 0%, 0%, 80%);
}


</style>