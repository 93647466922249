<script>
  import { getContext } from "svelte";
  import Table from 'sveltestrap/src/Table.svelte';
  import I18n from '../../utilities/I18n.js';

  const context = getContext('context');
  const { code, lighttool, lightcheck, editable, building } = context;

  let tableRows = [
    { label: I18n.t('lighttool.building.properties.addresses.site'), entry: building.properties.site },
    { label: I18n.t('lighttool.building.properties.addresses.principal'), entry: building.properties.principal},
    { label: I18n.t('lighttool.building.properties.addresses.applicant'), entry: building.properties.applicant}
  ]

  if (lighttool) {
    tableRows.push({ label: `${I18n.t('lighttool.building.properties.date')} ${I18n.t('lighttool.building.properties.addresses.proof')}`, entry: building.properties.analysis_date })
  }

  if (lightcheck) {
    tableRows.push({ label: I18n.t('lighttool.building.properties.addresses.examiner'), entry: building.properties.examiner })
    tableRows.push({ label: `${I18n.t('lighttool.building.properties.date')} ${I18n.t('lighttool.building.properties.addresses.control')}`, entry: building.properties.lightcheck_date })
  }

</script>

<style>
  tbody th {
    padding-left: 0;
  }
  tbody td:last-child {
    padding-right: 0;
  }
</style>

<Table>
  <tbody>
    {#each tableRows as r}
    <tr>
      <th scope="row">{r.label}</th>
      <td>{@html r.entry || '-'}</td>
    </tr>
    {/each}
  </tbody>
</Table>
