import I18n from './I18n.js'

export const isAlert = (item) => item.type == 'AlertQuestion'

// export const t = (object, property) => object[`${property}_${I18n.currentLocale()}`]

export const t = (input) => {
  if (input && typeof input === 'object') {
    if (input[I18n.currentLocale()]) return input[I18n.currentLocale()]
    else return input[I18n.defaultLocale]
  } else {
    return input
  }
}