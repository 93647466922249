<script>
  import { onMount } from 'svelte';

  import Button from 'sveltestrap/src/Button.svelte';
  import Alert from 'sveltestrap/src/Alert.svelte';
  import FormInput from "../generic/form/FormInput.svelte";
  import LightingConfig from "./LightingConfig.svelte";

  export let building;
  export let roomGroup;
  export let roomCheck = {};

  let usages = [];
  let usage_types = [];
  let hasError = false;

  const submitRoomGroup = () => {
    hasError = false;
    let req;
    if (roomGroup.id) {
      req = submit(`http://localhost:3000/lighttool/room_groups/${roomGroup.id}`, { room_group: roomGroup }, 'PUT')
    } else {
      req = submit(`http://localhost:3000/lighttool/room_groups`, { building_id: building.id, room_group: roomGroup }, 'POST')
    }
    req.then(() => back(), () => hasError = true)
  }

  const submit = async (url = '', data = {}, method) => {
    const response = await fetch(url, {
      method: method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    });
    return await response.json();
  }

  const loadRoomGroup = async (id) => {
    const response = await fetch(`/lighttool/room_groups/${id}`);
    const data = await response.json();
    data.old_config_attributes = {};
    data.new_config_attributes = {};
    roomGroup = data;
  }

  const back = () => {
    history.back();
  }
  
  onMount(async () => {
    let response = await fetch('/lighttool/usages');
    usages = await response.json();

    response = await fetch('/lighttool/usage_types')
    usage_types = await response.json();
  
    if (roomGroup.id) await loadRoomGroup(roomGroup.id);
  })

  $: usage = (roomGroup.lighttool_usage_id ? usages.filter(u => u.id === roomGroup.lighttool_usage_id)[0] : null);
  $: usage_type = (usage && usage_types.filter(ut => ut.id === usage.lighttool_usage_type_id)[0] ? usage_types.filter(ut => ut.id === usage.lighttool_usage_type_id)[0].name : null);
  $: illuminance = (usage ? usage.illuminance : null);

  let check_usage_type;
  let check_illuminance;
</script>

<form on:submit|preventDefault={submitRoomGroup}>

  {#if hasError}
    <Alert color="danger">Änderung konnte nicht gespeichert werden. Überprüfen Sie Ihre Eingabe.</Alert>
  {/if}

  <fieldset class="group col-xs-6">
    <legend><h3>Angaben zur Raumgruppe</h3></legend>
    <FormInput name="room_group_name" label="Name" bind:value={roomGroup.name} />
    <FormInput name="room_group_area" label="Fläche" bind:value={roomGroup.area} addon="m&sup2;" />
    <FormInput type="select" name="lamp_type" label="Standardnutzung" bind:value={roomGroup.lighttool_usage_id} options={usages} />
    <FormInput type="number" name="illuminance" label="Beleuchtungsstärke" addon="lx" bind:value={illuminance} readonly />
    <FormInput name="usage_type" label="Art der Nutzung" bind:value={usage_type} readonly />
  </fieldset>

  <fieldset class="group col-xs-6">
    <legend><h3>Controlling</h3></legend>
    <FormInput name="room_group_name" label="Name" bind:value={roomCheck.name} />
    <FormInput name="room_group_area" label="Fläche" bind:value={roomCheck.area} addon="m&sup2;" />
  </fieldset>

  <fieldset class="group col-md-6">
    <legend><h3>Neue Beleuchtung (Nachweis)</h3></legend>
    <LightingConfig bind:config={roomGroup.new_config_attributes} bind:configId={roomGroup.new_config_id} readonly />
  </fieldset>

  <fieldset class="group col-md-6">
    <legend><h3>Kontrolle</h3></legend>
    <LightingConfig bind:config={roomCheck.config_attributes} bind:configId={roomCheck.config_id} />
  </fieldset>

  <fieldset class="group col-xs-12">
    <br>
    <Button type="submit" color="primary">Kontrolle abschliessen</Button>
    <Button type="button" on:click={back}>Abbrechen</Button>
  </fieldset>

</form>
