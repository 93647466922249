<!-- adapted from https://github.com/vipero07/svelte-file-upload-component -->

<script>
  import { createEventDispatcher } from "svelte";

  export let name;
  export let multiple = false;
  export let disabled = false;
  export let data;
  export let mime;
  export let filename;
  const dispatch = createEventDispatcher();

  const handleInput = (e) => {
    if (e.target && e.target.files.length > 0) {
      filename = e.target.files[0].name
      const reader = new FileReader();
      reader.onload = (e) => {
        let filedata = e.target.result
        mime = filedata.match(/data:(\w+\/\w+);/).pop()
        data = filedata.split(',').pop()
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onFile = getFilesFunction => event => {
    const files = getFilesFunction(event);
    if (files.length) {
      dispatch("input", { files: multiple ? files : files[0] });
    }
  };

  const getFilesFromDropEvent = ({ dataTransfer: { files, items } }) => {
    return files.length
      ? [...files]
      : items
          .filter(({ kind }) => kind === "file")
          .map(({ getAsFile }) => getAsFile());
  }

  const getFilesFromInputEvent = ({ target }) => {
    const files = target.files ? [...target.files] : [];
    target.value = "";
    return files;
  }
</script>

<style>
  input.hidden-input {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
  }
  label {
    display: block;
  }
  label:hover {
    cursor: pointer;
  }
  label[disabled=true]:hover {
    cursor: not-allowed
  }
</style>

<label on:drop on:dragover on:dragleave {disabled} >
  <slot><input type="file" {multiple} on:input {name} /></slot>
  <input class="hidden-input" type="file" {multiple} on:input={handleInput} on:input {name} {disabled} />
</label>
