<script>
  import { derived } from 'svelte/store';

  import I18n from '../../utilities/I18n.js';
  import { t } from '../../utilities/general.js';
  import { getCSRFParam, getCSRFToken } from '../../utilities/html';

  import { Alert, Button } from 'sveltestrap';
  import AddressTable from './AddressTable.svelte';
  import FormInput from "../generic/form/FormInput.svelte";
  import FileUpload from "../generic/form/FileUpload.svelte";
  import EnergyBalanceTable from "./EnergyBalanceTable.svelte";
  import LuminaireTypesTable from './LuminaireTypesTable.svelte';
  import Configuration from './Configuration.svelte';


  import { configAttributes } from '../../stores/lighttool_exterior/configAttributesStores';
  import { luminaireTypes } from '../../stores/lighttool_exterior/luminaireTypesStore';
  import { siteAssessments } from '../../stores/lighttool_exterior/siteAssessmentsStore';

  export let editable,
             site = {},
             activeLuminaireType = {},
             debug = false;

  let errors = [],
      readonly = !editable,
      energyBalance = {},
      fileDragging = false,
      fileError = false,
      dirty = false,
      submitted = false;

  const calculateEnergyBalance = async () => {
    try {
      let url = `${site.paths.calculate}/?${encodeURIComponent(getCSRFParam())}=${encodeURIComponent(getCSRFToken())}`;
      let options = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ site })
      }
      let response = await fetch(url, options);

      if (response.ok) {
        let responseBody = await response.json();
        energyBalance = responseBody;
      } else {
        energyBalance = {}
      }
    } catch(e) {
      console.error('An error occurred calculating project values', e);
    }
  }

  function beforeUnload() {
    if (dirty && !submitted) {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
      // more compatibility
      return true;
    }
  }

  // initialize stores
  luminaireTypes.init(site.paths.luminaire_types);
  configAttributes.init(site.paths.configuration_attributes);
  siteAssessments.init(site.paths.site_assessments);
  const presenceControl = derived(configAttributes, $attributes => $attributes.presence_control);
  const daylightControl = derived(configAttributes, $attributes => $attributes.daylight_control);
  const brightness = derived(configAttributes, $attributes => $attributes.brightness);

  $: hours_of_operation_annual = (parseFloat(site.hours_of_operation_day) + parseFloat(site.hours_of_operation_night)) * parseInt(site.annual_days_of_operation) || '';
  $: illuminance_uniformity = Math.round(10 * (parseFloat(site.illuminance_min) / parseFloat(site.illuminance_med))) / 10;

  calculateEnergyBalance();
</script>

<style>
  .subtitle {
    font-weight: bold;
  }
  .picture-wrapper {
    border: 1px solid lightgray;
    width: 100%;
    padding-top: 75%;
    position: relative;
  }
  .picture-wrapper > img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .fileupload-placeholder {
    border: 1px solid grey;
    width: 100%;
    padding-top: 30%;
    padding-bottom: 30%;
    text-align: center;
  }
  .fileupload-placeholder > .glyphicon {
    font-size: 2.5em;
    margin-bottom: 0.25em;
  }
  .fileupload-placeholder.fileError {
    box-shadow: inset 0 0 12px #dc3545
  }
  .fileupload-placeholder.fileDragging {
    box-shadow: inset 0 0 12px #999
  }
  legend {
    border-bottom: none;
    margin: 12px 0
  }
  .debug {
    margin-top: 48px;
    text-align: left;
  }
</style>

<svelte:window on:beforeunload={beforeUnload} /> 

{#each site.errors as e}
  <Alert color="warning">{e}</Alert>
{/each}

<h2 class="subtitle">{site.properties.name}</h2>

<form id="buildingform"
      class="row"
      action={site.paths.update}
      method="post"
      enctype="multipart/form-data"
      on:change={() => { dirty = true }}>
  <!-- addresses -->
  <fieldset class="group col-xs-12">
    <legend><h3>{I18n.t('lighttool_exterior.section.addresses')}</h3></legend>
    <AddressTable {site} />
  </fieldset>

  <!-- site -->
  <fieldset class="group col-xs-12">
    <legend><h3>{I18n.t('lighttool_exterior.section.general')}</h3></legend>
    <div class="row">
      <FormInput input={site.form.inputs.name}
                 bind:value={site.name} />
      <FormInput input={site.form.inputs.area}
                 bind:value={site.area}
                 addon="m&sup2;"
                 on:change={calculateEnergyBalance} />
      <FormInput input={site.form.inputs.building_type}
                 value={site.properties.building_type}
                 disabled />
      <FormInput type="select"
                 input={site.form.inputs.lighttool_exterior_brightness_id}
                 options={$brightness}
                 addon="lx"
                 bind:value={site.lighttool_exterior_brightness_id} />
      <FormInput input={site.form.inputs.hours_of_operation_day}
                 bind:value={site.hours_of_operation_day}
                 on:change={calculateEnergyBalance} />
      <FormInput input={site.form.inputs.annual_days_of_operation}
                 bind:value={site.annual_days_of_operation}
                 on:change={calculateEnergyBalance} />
      <FormInput input={site.form.inputs.hours_of_operation_night}
                 bind:value={site.hours_of_operation_night}
                 on:change={calculateEnergyBalance} />
      <FormInput input={site.form.inputs.hours_of_operation_annual}
                 bind:value={hours_of_operation_annual} />
  </fieldset>

  <!-- check: illuminance -->
  {#if site.form.inputs.illuminance}
    <fieldset class="group col-xs-12">
      <legend><h3>{I18n.t('lighttool_exterior.section.check_illuminance')}</h3></legend>
      <div class="row">
        <FormInput input={site.form.inputs.illuminance.illuminance_min}
                   bind:value={site.illuminance_min}
                   addon="lx"
                   on:change={calculateEnergyBalance} />
        <FormInput input={site.form.inputs.illuminance.illuminance_med}
                   bind:value={site.illuminance_med}
                   addon="lx"
                   on:change={calculateEnergyBalance} />
        <FormInput input={site.form.inputs.illuminance.illuminance_max}
                   bind:value={site.illuminance_max}
                   addon="lx"
                   on:change={calculateEnergyBalance} />
        <FormInput input={site.form.inputs.illuminance.illuminance_uniformity}
                   bind:value={illuminance_uniformity} />
    </fieldset>
  {/if}

  <!-- luminaire types -->
  <fieldset class="group col-xs-12 luminaire-types">
    <legend><h3>{I18n.t('lighttool_exterior.section.luminaire_types')}</h3></legend>
    <LuminaireTypesTable {site} {debug} bind:activeLuminaireType />
  </fieldset>

  <!-- configurations -->
  {#each site.configurations as key}
    <Configuration bind:config={site[key]} {key} {site} on:change={calculateEnergyBalance} />
  {/each}

  <!-- energy balance -->
  <div class="col-xs-12">
    <h3>{I18n.t('lighttool_exterior.section.energy_balance')}</h3>
    <EnergyBalanceTable {energyBalance} />
  </div>

  {#if site.form.inputs.assessment}
  <fieldset class="group col-xs-12">
    <legend><h3>{I18n.t('lighttool_exterior.section.assessment')}</h3></legend>
    <div class="row">
      <FormInput type="select"
                input={site.form.inputs.assessment.assess_luminaires_id}
                options={$siteAssessments}
                bind:value={site.assess_luminaires_id} />
      <FormInput type="select"
                input={site.form.inputs.assessment.assess_illuminance_id}
                options={$siteAssessments}
                bind:value={site.assess_illuminance_id} />
      <FormInput type="select"
                input={site.form.inputs.assessment.assess_uniformity_id}
                options={$siteAssessments}
                bind:value={site.assess_uniformity_id} />
      <FormInput type="select"
                input={site.form.inputs.assessment.assess_glaring_id}
                options={$siteAssessments}
                bind:value={site.assess_glaring_id} />
      <FormInput type="select"
                input={site.form.inputs.assessment.assess_lighting_control_id}
                options={$siteAssessments}
                bind:value={site.assess_lighting_control_id} />
      <FormInput type="select"
                input={site.form.inputs.assessment.assess_energy_demand_id}
                options={$siteAssessments}
                bind:value={site.assess_energy_demand_id} />
    </div>
    <FormInput type="textarea"
               input={site.form.inputs.assessment.assess_comment}
               bind:value={site.assess_comment}
               rows="6"
               fullwidth />
  </fieldset>
  {/if}

  <!-- comment and picture -->
  <fieldset class="group col-xs-12 col-md-6">
    {#if site.picture_editable}
      <FileUpload name="site[picture_data]"
                  bind:data={site.picture_data}
                  bind:filename={site.picture_filename}
                  bind:mime={site.picture_mime}
                  on:dragover={() => { fileDragging = true; fileError = false }}
                  on:dragleave={() => { fileDragging = false } }>
        {#if site.picture_data}
          <div><span class="glyphicon glyphicon-picture"/>&nbsp;{site.picture_filename}</div>
          <div class="picture-wrapper">
            <img src={`data:${site.picture_mime};base64, ${site.picture_data}`}  alt="({I18n.t('lighttool_exterior.asset.preview')} {I18n.t('lighttool_exterior.asset.failed_to_load')})" />
          </div>
          {:else}
          <div class="fileupload-placeholder" class:fileError class:fileDragging>
            <span class="glyphicon glyphicon-open"/><br>{I18n.t('lighttool_exterior.asset.name.picture')}&nbsp;{I18n.t('lighttool_exterior.asset.upload')}
          </div>
        {/if}
      </FileUpload>
    {:else if site.picture_data}
      <div><span class="glyphicon glyphicon-picture"/>&nbsp;{site.picture_filename}</div>
      <div class="picture-wrapper">
        <img src={`data:${site.picture_mime};base64, ${site.picture_data}`}  alt="({I18n.t('lighttool_exterior.asset.preview')} {I18n.t('lighttool_exterior.asset.failed_to_load')})" />
      </div>
    {/if}
  </fieldset>

  <!-- form footer -->
  <fieldset class="group col-xs-12">
    <legend>&nbsp;</legend>
    {#if editable}
      <Button type="submit" color="primary" on:click={() => submitted = true}>{I18n.t('lighttool_exterior.application.save')}</Button>
    {/if}
    <a href={site.paths.return} class="btn btn-default">{I18n.t('lighttool_exterior.application.close')}</a>
  </fieldset>

  <input type="hidden" name={getCSRFParam()} value={getCSRFToken()} />
  <input type="hidden" name="_method" value="patch" />
</form>

{#if debug}
  <pre class="debug">{JSON.stringify(site, null, 2)}</pre>
{/if}