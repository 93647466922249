<script>
  import I18n from "../../utilities/I18n.js";
  import { t } from "../../utilities/general.js";
  import { getCSRFParam, getCSRFToken } from "../../utilities/html";

  import { luminaireCategories } from "../../stores/lighttool_exterior/luminaireCategoriesStore";
  import { lampTypes } from "../../stores/lighttool_exterior/lampTypesStore";
  import { luminaireTypes } from "../../stores/lighttool_exterior/luminaireTypesStore";

  import Modal from "sveltestrap/src/Modal.svelte";
  import ModalHeader from "sveltestrap/src/ModalHeader.svelte";
  import ModalBody from "sveltestrap/src/ModalBody.svelte";
  import ModalFooter from "sveltestrap/src/ModalFooter.svelte";
  import Button from "sveltestrap/src/Button.svelte";
  import Alert from "sveltestrap/src/Alert.svelte";
  import Confirm from "../generic/Confirm.svelte";
  import FormInput from "../generic/form/FormInput.svelte";
  import FileUpload from "../generic/form/FileUpload.svelte";

  export let luminaireType = {},
    site = {},
    isOpen = false,
    debug = false;

  let form,
    errors = [];

  const toggle = () => {
    isOpen = !isOpen;
    errors = [];
  };

  const handleFormSubmit = async () => {
    const url = luminaireType.id ? luminaireType.paths.update : site.paths.luminaire_types.create;
    const data = new FormData(form);
    let result;

    if (luminaireType.id) {
      result = await luminaireTypes.put(url, data);
    } else {
      result = await luminaireTypes.post(data);
    }

    errors = result.error || [];

    if (errors.length < 1) toggle();
  };

  const confirmDestroy = async () => {
    const url = `${luminaireType.paths.delete}/?${encodeURIComponent(getCSRFParam())}=${encodeURIComponent(getCSRFToken())}`;
    const result = await luminaireTypes.delete(url);

    errors = result.error || [];

    if (errors.length < 1) toggle();
  };

  const handleFileInput = (e) => {
    if (e.target && e.target.files.length > 0) luminaireType.datasheet_upload_filename = e.target.files[0].name;
  };

  const luminaireTypeSort = (a, b) => {
    if (t(a.full_name) < t(b.full_name)) return -1;
    if (t(a.full_name) > t(b.full_name)) return 1;
    return 0;
  };

  // initialize stores
  lampTypes.init(site.paths.lamp_types);
  luminaireCategories.init(site.paths.luminaire_categories);

  $: luminaireType.full_name = [
    luminaireType.lighttool_exterior_luminaire_category_id ? t($luminaireCategories.find((c) => c.id == luminaireType.lighttool_exterior_luminaire_category_id).name) : "",
    luminaireType.name,
    luminaireType.operating_wattage ? `${luminaireType.operating_wattage} W` : luminaireType.connected_wattage ? `${luminaireType.connected_wattage} W` : "",
  ].join(" ");
</script>

<Modal {isOpen} {toggle}>
  <form id="luminairetypeform" on:submit|preventDefault={handleFormSubmit} enctype="multipart/form-data" bind:this={form}>
    <ModalHeader>
      <h4>
        {luminaireType && luminaireType.id
          ? I18n.t("helpers.titles.edit", { model: I18n.t("activerecord.models.lighttool_exterior/luminaire_type", { count: 1 }) })
          : I18n.t("helpers.titles.new_luminaire_type")}
      </h4>
      <h5>{luminaireType.full_name}</h5>
    </ModalHeader>
    {#if luminaireType.id && luminaireType.in_use}
      <Alert color="info">{@html I18n.t("lighttool_exterior.notices.luminaire_type.edit_in_use_html")}</Alert>
    {/if}
    {#each errors as e}
      <div class="alert alert-warning">{@html e}</div>
    {/each}
    <ModalBody>
      <FormInput hidden name="luminaire_type[id]" value={luminaireType.id} />
      <FormInput
        type="select"
        name="luminaire_type[lighttool_exterior_luminaire_category_id]"
        label={I18n.t("activerecord.attributes.lighttool_exterior/luminaire_type.luminaire_category")}
        value={luminaireType.lighttool_exterior_luminaire_category_id}
        options={$luminaireCategories}
        fullwidth />
      <FormInput name="luminaire_type[name]" label={I18n.t("activerecord.attributes.lighttool_exterior/luminaire_type.name")} value={luminaireType.name} fullwidth />
      <FormInput name="luminaire_type[producer]" label={I18n.t("activerecord.attributes.lighttool_exterior/luminaire_type.producer")} value={luminaireType.producer} fullwidth />
      <FormInput
        type="select"
        name="luminaire_type[lighttool_exterior_lamp_type_id]"
        label={I18n.t("activerecord.attributes.lighttool_exterior/luminaire_type.lamp_type")}
        value={luminaireType.lighttool_exterior_lamp_type_id}
        options={$lampTypes}
        fullwidth />
      <FormInput
        type="number"
        step="0.1"
        name="luminaire_type[connected_wattage]"
        label={I18n.t("activerecord.attributes.lighttool_exterior/luminaire_type.connected_wattage")}
        value={luminaireType.connected_wattage}
        addon="W"
        tooltip={I18n.t("lighttool.luminaire.connected_wattage_tooltip")}
        fullwidth />
      <FormInput
        type="number"
        name="luminaire_type[luminous_flux]"
        label={I18n.t("activerecord.attributes.lighttool_exterior/luminaire_type.luminous_flux")}
        value={luminaireType.luminous_flux}
        addon="lm"
        fullwidth />
      <FormInput
        type="number"
        step="0.1"
        name="luminaire_type[operating_wattage]"
        label={I18n.t("activerecord.attributes.lighttool_exterior/luminaire_type.operating_wattage")}
        value={luminaireType.operating_wattage}
        addon="W"
        tooltip={I18n.t("lighttool.luminaire.operating_wattage_tooltip")}
        fullwidth />
      {#if luminaireType.datasheet_url}
        <div class="form-group datasheet-download">
          <a href={luminaireType.datasheet_url} target="_blank" class="btn">
            <span class="glyphicon glyphicon-file" />
            {I18n.t("documents.buttons.download", { property_name: I18n.t("activerecord.attributes.lighttool_exterior/luminaire_type.datasheet") })}
          </a>
        </div>
      {/if}
      <FileUpload name="luminaire_type[datasheet_data]" style="margin-top: 10px" on:input={handleFileInput}>
        <div class="datasheet-placeholder">
          {#if luminaireType.datasheet_upload_filename}
            <span class="glyphicon glyphicon-open-file" />
            <br />
            {luminaireType.datasheet_upload_filename}
          {:else if luminaireType.datasheet_url}
            <span class="glyphicon glyphicon-transfer" />
            <br />
            {I18n.t("documents.buttons.replace", { property_name: I18n.t("activerecord.attributes.lighttool_exterior/luminaire_type.datasheet") })}
          {:else}
            <span class="glyphicon glyphicon-open" />
            <br />
            {I18n.t("documents.buttons.upload", { property_name: I18n.t("activerecord.attributes.lighttool_exterior/luminaire_type.datasheet") })}
          {/if}
        </div>
      </FileUpload>
    </ModalBody>
    <ModalFooter>
      {#if luminaireType.id && !luminaireType.in_use}
        <span class="delete-button">
          <Confirm confirmTitle="Delete" cancelTitle="Cancel" let:confirm={confirmThis}>
            <button class="btn-link" type="button" on:click={() => confirmThis(confirmDestroy)}>
              <span title={I18n.t("lighttool_exterior.luminaire.luminaire_type.delete")} class="glyphicon glyphicon-trash" />
            </button>
          </Confirm>
        </span>
      {/if}
      <Button color="primary" type="submit">{I18n.t("lighttool_exterior.application.save_and_close")}</Button>
      <Button color="secondary" type="button" on:click={toggle}>{I18n.t("lighttool_exterior.application.abort")}</Button>

      {#if debug}
        <pre class="debug">{JSON.stringify(luminaireType, null, 2)}</pre>
      {/if}
    </ModalFooter>
    <input type="hidden" name={getCSRFParam()} value={getCSRFToken()} />
  </form>
</Modal>

<style>
  .datasheet-download {
    margin-left: -12px;
  }
  .datasheet-placeholder {
    border: 1px solid grey;
    width: 100%;
    padding-top: 10%;
    padding-bottom: 10%;
    text-align: center;
  }
  .datasheet-placeholder > .glyphicon {
    font-size: 2.5em;
    margin-bottom: 0.25em;
  }
  .delete-button {
    float: left;
  }
  .debug {
    margin-top: 48px;
    text-align: left;
  }
</style>
